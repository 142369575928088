
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    background-color: $bestzantaclawyers-grey-light;
    padding: 50px 0;
    h2 {
        color: $bestzantaclawyers-blue;
        font-weight: 700;
        line-height: 38px;
        @apply text-xl mb-4;
        @include md {
            @apply text-2xl mb-4;
        }
    }

    p {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        @apply text-sm;
        @include md {
            @apply text-base;
        }
        letter-spacing: 0em;
        color: $bestzantaclawyers-grey;
    }

    li {
        color: $bestzantaclawyers-grey;
        line-height: 32px;
        font-weight: 400;

        letter-spacing: 0em;
        span {
            font-weight: 700;
        }
    }
}
